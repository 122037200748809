import Icon from "./Icon";
export default {
  APP_ID: "ADMIN",
  APP_NAME: "ADMIN",
  TABLE: {
    ROWS_DEFAULT: 20,
    ROWS_DEFAULT_NEW: 10,
    SIZE_PER_PAGE: [5, 10, 20, 50, 100],
  },
  BUTTON: "/BTN/",
  BTN_NAME_SCREEN: {
    APPLICATIONLIST: "APPLICATIONLIST/",
    SECMODULE: "SECMODULE/",
    LISTGROUPUSER: "LISTGROUPUSER/",
    LISTGROUP: "LISTGROUP/",
    APDOMAIN: "APDOMAIN/",
    TITLEPERMISSION: "TITLEPERMISSION/",
    GRANTPERMISSION: "GRANTPERMISSION/",
    TEMPLATEEMAILSMS: "TEMPLATEEMAILSMS/",
    MASTERDATADISTRIBUTION: "MASTERDATADISTRIBUTION/",
    MONITORSYNCMASTERDATA: "MONITORSYNCMASTERDATA/",
    SECCHANEL: "SECCHANEL/",
    USERINFO: "USERINFO/",
    ACCESSLOG: "ACCESSLOG/",
    FOLLOWCHANGE: "FOLLOWCHANGE/",
    DATAPERMISSION: "DATAPERMISSION/",
    LOGO: "LOGO/",
  },
  ACTION: {
    INSERT: "INSERT",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    CLONE: "CLONE",
    VIEW: "VIEW",
    ADD_ACCOUNT: "ADD_ACCOUNT",
    VIEW_ROLE: "VIEWROLE",
    UPLOAD: "UPLOAD",
  },
  STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  STATUS_TICKET: {
    COMPLETED: 0,
    INPROGRESS: 1,
    CANCELLED: 2,
    PENDING: 3,
    RETURNED: 4,
  },

  SERVICE_CODE: {
    SUCC_CODE: "API-000",
    DUPLICATE_CODE: "APP982",
    SYSTEM_ERROR: "APP001",
    ADMIN_ERROR: "APP-001",
    NOT_FOUND: "APP002",
    DUPLICATE: "APP003",
    LOGIC_ERROR: "APP004",
    NULL_OBJ: "APP100",
    FILE_NOT_FOUND: "FILE_NOT_FOUND",
  },
  DATE_FORMAT: "DD/MM/YYYY",
  DATE_FORMAT_FULL: "DD/MM/YYYY HH:mm:ss",
  DATE_FORMAT_REV: "YYYY-MM-DD",
  DATE_TIME_FORMAT_REV: "YYYY-MM-DD HH:mm:ss",
  TIME_FORMAT : 'HH:mm:ss',
  DEFAULT_TIME: "DEFAULT_TIME",
  DECIMAL_SEPARATOR: ".",
  THOUSAND_SEPARATOR: ",",
  DATE_FORMAT_DATE: "YYYY-MM-DD HH:mm:ss",
  SHOP_TYPE: "shop_type",
  USER_SHOP_ID: 1,
  NOTIFY: {
    SEEN: 1,
    NO_SEEN: 0,
  },
  NOTIFY_TYPE_AP_DOMAIN: "NOTIFY_HISTORY_TYPE",
  DELETE_GROUP: {
    PARENT_CODE: {
      ADMIN: "ADMIN",
      CON: "CON",
      EAPP: "EAPP",
      EAP: "EAP",
      CRM: "CRM",
      IHRP: "IHRP",
    },
    PARENT_SELECT_CODE: {
      ADMIN: "1",
      CON: "CON",
      EAPP: "EAPP",
      EAP: "EAP",
      CRM: "CRM",
      IHRP: "IHRP",
    },
  },
  APP_CODE: {
    ADMIN: "ADMIN",
    CON: "CON",
    EAPP: "eAPP",
    EAP: "EAP",
    CRM: "CRM",
    IHRP: "iHRP",
    SAP_PIPO: "SAP_PIPO",
    USER_NO_ACCOUNT: "userNoAccount_employee",
    USER_ALL: "userAll_employee",
    ROOT_KEY: "$$ROOT",
    ROOT_USER_NO_ACCOUNT_KEY: "$$ROOT_USER_NO_ACCOUNT",
    ROOT_USER_ALL_KEY: "$$ROOT_USER_ALL",
  },
  USER_INFO_URL: {
    USER_INFO: "/account",
    CHANGE_PASSWORD: "/account/password",
  },
  SYMBOL: {
    UNIQUE_APP_CHARACTOR: "|",
  },

  CHECK_PERMISSION_BUTTON: false,

  REALM_NAME: {
    STAFF: "dxg",
    CUSTOMER: "constractor",
    CONSTRUCTION: "customer",
  },

  AP_DOMAIN_CODE: {
    STAFF: "USER_TYPE_STAFF",
  },

  MASTER_DATA_DOMAIN: "MASTER_DATA_DOMAIN",

  MASTER_DATA_STATUS: {
    WAITING: "Waiting",
    DONE: "Done",
    ERROR: "Error",
    RESOLVE: "Resolve",
  },

  MASTER_DATA_COLOR: {
    BLUE: "blue",
    GREEN: "green",
    RED: "red",
  },
  MASTER_DATA_COLOR_LABEL: {
    IHRP: "green",
    SAP: "blue",
    EAP: "red",
    ADM: "pink",
    ADMIN: "pink",
    CRM: "orange",
    CON: "purple",
    SAP_PIPO: "yellow",
    CFS: "#fcf097",
    "Không xác định": "orange",
  },
  TYPE_COLOR_LABEL: {
    UPDATE: "orange",
    INSERT: "green",
    VIEW: "blue",
    DELETE: "red",
  },

  MASTER_DATA_BUTTON_COLOR: {
    BLUE: "button-color-blue",
    GREEN: "button-color-green",
    RED: "button-color-red",
  },

  TABLE_ICON: Icon,
  PURIFY_CONFIG: {
    USE_PROFILES: { svg: true, svgFilters: true },
    ALLOWED_TAGS: [
      "svg",
      "path",
      "g",
      "circle",
      "rect",
      "text",
      "line",
      "polygon",
      "polyline",
    ],
    FORBID_TAGS: ["*"],
    FORBID_ATTR: ["*"],
    // !Block all Tag except svg and path tags
    // ? Block all attribute injection
  },
  ICON_COLOR: "#8C8C8C",
  ICON_BACKROUND_COLOR: "none",
  CONVENTION: {
    TRUE: "YES",
    FALSE: "NO",
  },
  SubSystemStatus: {
    ON: 1,
    OFF: 0,
  },
  ApproveScope: {
    FALSE: "LOCAL",
    TRUE: "GLOBAL",
  },
  OTP_COUNTDOWN: 60,
  OTP_LENGTH: 6,
  FULL_SCREEN_HEIGHT: "100vh - 235px",
  DETAIL_LIST_USER_SCREEN: {
    GRANT_PERMISSION_EAP: 6,
    LIST_GROUP_USER: 0,
  },
  ATRIBUTE_BLOCK_LIST: [
    "EMAIL_SERVER",
    "EMAIL_SENDER",
    "EMAIL_USER",
    "EMAIL_PORT",
    "EMAIL_PASSWORD",
  ],
  MAX_TAG_LENGTH: 32,
  INPUT_DEBOUNCE: 500,
  MAIL_SERVER_TYPE: "CHANNEL_PARAMETER",
  PERMISSION_TYPE: {
    0: "Quuyền chuẩn",
    1: "Quyền ngoại lệ",
    2: "Quyền kiêm nhiệm",
  },
  TYPE: {
    CHANNEL: [
      { label: "SMS", value: "SMS" },
      { label: "EMAIL", value: "EMAIL" },
    ],
  },
  IMAGE_EXTENTION: [
    "pjp",
    "jpg",
    "pjpeg",
    "jpeg",
    "jfif",
    "png",
    "gif",
    "bmp",
    "webp",
    "tif",
    "tiff",
  ],
  SW_HISTORY_COLOR_MAP: {
    DELETE: "red",
    INSERT: "green",
  },
  IMAGE_TYPE: ["image/jpeg", "image/png", "image/webp", "image/x-icon"],
  DOCUMENT_TYPE: ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  EXCEL_TYPE: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  TEXT_TYPE: ["text/plain"],
  CONTACT_TABLE: {
    TABLE_NAME: {
      CONTACT_INFO: "CONTACT_INFO",
      G_CONTACT_INFO: "G_CONTACT_INFO",
    },
  },
};
