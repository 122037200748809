import React, { Component } from 'react';
import { stringNullOrEmpty } from '../../components/category/CommonUtils';
import IconSVG from './IconSVG';

class MenuItem extends Component {
    render() {
        const item = this.props.item

        const iconServer = item?.iconType === 0 ? (
            <li
                className='left_item'
                id={`${this.props.item.to}`}
                style={{
                    height: '50%',
                    scale: '.95',
                    display: 'flex',
                    alignItems: 'center',
                }}
                dangerouslySetInnerHTML={{
                    __html: this.props.item.icon,
                }}
            >
                {/* <img src='http://localhost:3000/assets/layout/images/favicon.ico'/> */}
            </li>
        ) : (
            <li
                className='left_item'
                id={`${this.props.item.to}`}
                style={{
                    height: '50%',
                    scale: '.95',
                    display: 'flex',
                    alignItems: 'center',
                }}

            >
                <img src={item.icon}/>
            </li>
        )
        const localIcon = (
            <li
                id={`${this.props.item.to}`}
                style={{
                    height: '50%',
                    scale: '.95',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                {IconSVG[this.props.text] ? IconSVG[this.props.text] : IconSVG.DocumentSVG}
            </li>
        );
        const renderProps = !stringNullOrEmpty(this.props.item.icon)
            ? iconServer
            : localIcon;

        return <>{renderProps}</>;
    }
}

export default MenuItem;
